<template>
  <div class="bg-cercles">
    <div class="bb-login">
      <div class="bb-container">
        <div class="bb-login__container">
          <div class="login-box">

            <form @submit.prevent="submit" class="form">
              <section class="content">
                <h1 class="login-box__title">{{ $t("login.recover.remeberPassword") }}</h1>

                <div class="login-box__description">
                  {{ $t("login.recover.description") }}
                </div>
                <div class="login-box__inputs">
                  <div class="bb-form-item">
                    <label for="email" class="bb-form-label">{{ $t("login.yourEmail") }}:</label>
                    <input
                        class="bb-form"
                        v-model="email"
                        type="email"
                        :invalid="emailEmpty"
                        :placeholder="$t('login.yourEmail')"
                        autocomplete="on"
                    />
                  </div>
                </div>
                <div class="actions">
                  <div class="login-box__actions">
                    <input
                        type="submit"
                        :disabled="!valid"
                        :value="$t('login.recover.remeberPasswordCTA')"
                        :data-wait="$t('login.recover.justASecond')"
                        class="button button--primary button--block button--lg"
                    />
                  </div>
                </div>

                <div class="alert alert--error" v-if="errorMessage">{{ this.errorMessage }}</div>
                <div class="alert alert--success" v-if="successMessage">{{ this.successMessage }}</div>
              </section>

              <transition name="fade">
                <Loading v-if="loading" class="overlay" />
              </transition>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecoverPwd",
  metaInfo() {
    return {
      title: this.$t("login.recover.metaTitle") + " | HelloBB",
      meta: [
        { name: "description", content: this.$t("login.recover.metaDescription") },
        { name: "robots", content: "noindex"}
      ]
    };
  },
  data() {
    return {
      loading: false,
      email: "",
      emailEmpty: false,
      errorMessage: null,
      successMessage: null,
    };
  },
  computed:{
    valid() {
      return this.$utils.validateEmail(this.email)
    }
  },
  methods: {
    async submit() {
      if (this.valid && !this.loading) {
        this.errorMessage = null;
        this.successMessage = null;
        this.loading = true;
        try {
          await this.operation();
        } catch (e) {
          //this.errorMessage = e.message;
        }
        this.loading = false;
      }
    },
    async operation() {
      if (this.validations()) {
        await this.recoverPwd();
      }
    },
    async recoverPwd() {
      const response = await this.$store.dispatch('resetPasswordPwd',this.email);
      // if (response && parseInt(response.status) === 409) {
      //   this.errorMessage = "Este email no ha sido registrado.";
      // } else {
      //   this.successMessage = "Email enviado correctamente.";
      // }
      this.successMessage = this.$t("login.recover.success");
    },
    validations() {
      this.emailEmpty = !this.email;
      return !!this.email;
    },
  },
};
</script>

